var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{ref:"modal-massive-weight",attrs:{"title":_vm.isTraining ? 'Enviar ejemplares a Pre-listos' : 'Agregar pesos a ejemplares',"no-close-on-backdrop":"","centered":"","size":"xlg","hide-footer":""},on:{"hidden":function($event){return _vm.$emit('closing')}},model:{value:(_vm.isActive),callback:function ($$v) {_vm.isActive=$$v},expression:"isActive"}},[_c('div',[(!_vm.isTraining)?_c('b-card',{},[_c('b-row',[_c('b-col',{attrs:{"sm":"12","md":"12","lg":"4"}},[_c('div',[_c('label',{attrs:{"for":""}},[_vm._v("Ejemplar:")]),_c('SelectSpecimens',{attrs:{"selectedSpecimens":_vm.S_MASSIVELY_WEIGHTS,"cleanSelected":"","statusList":"1,9,10,13,6","categories":"1,2,3,4"},on:{"selected":_vm.selected}})],1)]),_c('b-col',{attrs:{"sm":"10","md":"8","lg":"7"}},[_c('GlobalChangesWeight',{attrs:{"typeWeightOptions":_vm.S_TYPE_WEIGHT_OPTIONS}})],1),_c('b-col',{staticClass:"align-items-center d-flex justify-content-center",attrs:{"sm":"2","md":"1","lg":"1"}},[_c('feather-icon',{staticClass:"text-danger cursor-pointer",attrs:{"size":"25","icon":"Trash2Icon"},on:{"click":function($event){_vm.M_CLEAN_MASSIVELY_WEIGHTS()
							_vm.M_CLEAN_ARRAY_IMAGES()}}})],1)],1)],1):_vm._e(),_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(_vm.S_MASSIVELY_WEIGHTS.length)?_c('b-card',{staticClass:"p-1"},[_c('div',[_c('ValidationObserver',{ref:"form"},_vm._l((_vm.S_MASSIVELY_WEIGHTS),function(specimen,index){return _c('b-row',{key:index,staticClass:"rounded",class:{
								'mb-2': !(index === _vm.S_MASSIVELY_WEIGHTS.length - 1),
							},staticStyle:{"-webkit-box-shadow":"2px 6px 19px -6px rgba(0, 0, 0, 0.31)","-moz-box-shadow":"2px 6px 19px -6px rgba(0, 0, 0, 0.31)","box-shadow":"2px 6px 19px -6px rgba(0, 0, 0, 0.31)","padding-top":"10px","padding-bottom":"10px","position":"relative"}},[_c('div',{staticStyle:{"position":"absolute","top":"10px","left":"10px"}},[_c('b-badge',{staticStyle:{"font-size":"12px"},attrs:{"variant":"light-dark"}},[_vm._v("# "+_vm._s(index + 1))])],1),_c('b-col',{staticClass:"align-items-center d-flex py-1 justify-content-center",attrs:{"sm":"12","md":"12","lg":"4"}},[_c('div',{staticClass:"p-1 rounded",staticStyle:{"-webkit-box-shadow":"2px 6px 19px -6px rgba(0, 0, 0, 0.31)","-moz-box-shadow":"2px 6px 19px -6px rgba(0, 0, 0, 0.31)","box-shadow":"2px 6px 19px -6px rgba(0, 0, 0, 0.31)"}},[_c('SpecimenPlate',{attrs:{"specimen":{
											id: specimen.id,
											plate: specimen.plate,
											alias: specimen.alias,
											thumb: specimen.thumb,
										}}})],1)]),_c('b-col',{attrs:{"sm":"12","md":(_vm.isTraining && _vm.S_MASSIVELY_WEIGHTS.length > 1) || !_vm.isTraining ? 11 : 12,"lg":(_vm.isTraining && _vm.S_MASSIVELY_WEIGHTS.length > 1) || !_vm.isTraining ? 7 : 8}},[_c('div',{},[_c('AddSpecimenWeightBody',{attrs:{"specimenWeight":specimen,"typeWeightOptions":_vm.S_TYPE_WEIGHT_OPTIONS,"isTraining":_vm.isTraining}})],1)]),(!_vm.isTraining || (_vm.isTraining && _vm.S_MASSIVELY_WEIGHTS.length > 1))?_c('b-col',{staticClass:"align-items-center d-flex justify-content-center",class:{ 'pb-50': !_vm.isTraining, 'pt-2': _vm.isTraining },attrs:{"md":"1","lg":"1"}},[_c('feather-icon',{staticClass:"text-danger cursor-pointer",attrs:{"size":"20","icon":"Trash2Icon"},on:{"click":function($event){_vm.M_REMOVE_SPECIMEN_WEIGHT(specimen.specimen_id),
											_vm.removeSpecimenTraining(specimen.specimen_id)}}})],1):_vm._e()],1)}),1)],1)]):_c('b-card',[_c('div',{staticClass:"d-flex justify-content-center align-items-center w-100",staticStyle:{"height":"100px"}},[_c('h4',[_vm._v("No se agregaron ejemplares")])])])],1),(_vm.S_MASSIVELY_WEIGHTS.length)?_c('div',{staticClass:"d-flex justify-content-end"},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.saveWeightsMassively}},[_vm._v("Guardar")])],1):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }